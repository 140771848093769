<template>
  <div class="footer_container">
    <div class="footer-wrapper-top">
      <div class="footer-inner-top">
        <div class="footer-links" :class="columns">
          <!-- <a href="/index.html">HOME</a> -->
          <div v-for="links in footer_links" :key="links.group">
            <div class="footer-links-heading">{{ links.group }}</div>
            <a
              v-bind:href="a.url"
              :key="a.mid"
              v-show="a.label !== ''"
              class="footer-links-main"
              :class="a.mtype ? a.mtype.toLowerCase() : ``"
              :target="[
                a.mtype && a.mtype.toLowerCase() === 'external'
                  ? '_blank'
                  : '_self',
              ]"
              v-for="a in links.data"
              >{{ getrans(a.label) }}</a
            >
          </div>
        </div>
        <div class="footer-logo"></div>
      </div>
    </div>
    <div class="footer-wrapper-bottom">
      <div class="footer-inner-bottom">
        <span v-if="getSlug() == 'RDU'">
          © {{ new Date().getFullYear() }} RDUAA<br> {{ getrans("All Rights Reserved") }}.
        </span>
        <span v-else-if="getSlug() == 'SV1'">
          Copyright © {{ new Date().getFullYear() }} Summit Airport Parking
        </span>
        <span v-else
          >{{ getrans("Copyright ") }} © {{ getSiteName() }}
          {{ new Date().getFullYear() }}.
          {{ getrans("All Rights Reserved") }}.</span
        >
        <span v-if="getSlug() !== 'PV1'">
          <template v-if="this.brandSlug === 'AB1' || this.brandSlug === 'RSW' || this.brandSlug === 'LAX'">
            {{ getrans("Powered by ABM Vantage") }}
          </template>
          <template v-else>
            {{ getrans("Powered by") }}
            <a
              style="color: currentcolor"
              href="http://www.rezcomm.com/"
              target="_blank"
              >Rezcomm</a
            >
          </template>
        </span>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
//import md5 from "js-md5";
import md5 from "js-md5";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Footer",
  props: {
    footer_links: undefined,
    footer_columns: undefined,
    lang: undefined,
    translations: undefined,
    brandSlug: undefined,
  },
  data() {
    return {
      copyright: "Copyright © 2022. All rights reserved.",
      poweredby: "Powered by Rezcomm",
    };
  },
  methods: {
    ...mapActions(["setSlug", "setBranding"]),
    ...mapGetters(["getSlug", "getBranding", "getSiteName"]),
    getrans(string) {
      var s = md5(string);
      var ss = this.getSlug() + "-" + this.lang.toUpperCase() + "-" + s;
      console.log(ss);
      return this.translations[ss] || string;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.footer_container {
  text-align: left;
  height: auto;
  position: relative;
  // margin-top: 40px;
  @media screen and (max-width: 567px) {
    margin-top: 0px;
  }
}

.footer-logo {
  width: var(--footer_logo_width);
  height: var(--footer_logo_height);
  background-image: var(--footer_logo);
  background-size: contain;
  background-repeat: no-repeat;
  align-items: center;
  display: var(--footer_logo_active, none);
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: var(--footer_logo_width);
  }
}

.footer-wrapper-top,
.footer-wrapper-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}

.footer-wrapper-top {
  border-top: 1px solid var(--footer_top_border_top_color);
  background-color: var(--footer_top_background_color, $footer-top-bg);
  color: var(--footer_top_text_color, $footer-top-text);
  font-family: var(--footer_top_font_family, $footer-top-font);
  font-size: var(--footer_top_font_size, 1rem);
  font-weight: var(--footer_top_font_weight, normal);
}

.footer-wrapper-bottom {
  border-top: 1px solid var(--footer_bottom_border_top_color);
  background-color: var(--footer_bottom_background_color, $footer-bottom-bg);
  color: var(--footer_bottom_text_color, $footer-bottom-text);
  font-family: var(--footer_bottom_font_family, $footer-bottom-font);
  font-size: var(--footer_bottom_font_size, 1rem);
  font-weight: var(--footer_bottom_font_weight, normal);
}

.footer-inner-top {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    align-items: center;
    padding-bottom: 2rem;
  }
  .footer-links {
    padding: 60px 0;
    display: grid;
    width: 100%;
    padding: 2rem 1rem 1rem;
    min-height: 7rem;

    &.grid-1-columns {
      grid-template-columns: 1fr;
    }

    &.grid-2-columns {
      grid-template-columns: 1fr 1fr;
    }

    &.grid-3-columns {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.grid-4-columns {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    a {
      margin: 10px 0;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr !important;
      a {
        margin: 10px 0;
      }
    }

    &-heading {
      font-weight: 700;
    }

    &-main {
      display: inline-block;
    }
  }
  .footer-links a {
    color: var(--footer_top_text_color, #fff);
    text-decoration: none;
    padding: 0rem 2rem 2rem 0;
    &:hover {
      color: var(--footer_top_text_color_hover, --footer_top_text_color);
      text-decoration: underline;
    }
    @media screen and (max-width: 768px) {
      padding: 0 10px;
      width: 100%;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.footer-inner-bottom {
  padding: 30px 10px;
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 30px 30px;
    span {
      padding: 20px 0;
    }
  }
}
</style>