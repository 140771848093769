<template>
  <div class="results-banner">
    <div class="results-banner__heading">
      <a href="/dashboard.html">
        <span class="pagelink" :class="active === 'dashboard' ? 'active' : ''"
          ><slot name="dashboard"></slot
        ></span>
      </a>
      <a href="/bookings.html">
        <span class="pagelink" :class="active === 'manage' ? 'active' : ''"
          ><slot name="manage"></slot
        ></span>
      </a>
      <a href="/updateprofile.html" v-if="profile_whitelist_brand.indexOf(getSlug()) > -1">
        <span class="pagelink" :class="active === 'profile' ? 'active' : ''"
          ><slot name="profile"></slot
        ></span>
      </a>
    </div>
    <div class="pagetitle"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ResultsBanner",
  props: {
    active: undefined,
  },
  computed: {},
  data() {
    return {
      showSearchWidget: false,
      profile_whitelist_brand: process.env.VUE_APP_ENABLE_PROFILE || [],
    };
  },
  methods: {
    ...mapGetters(["getSlug"]),
  },
  updated() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.search-widget-results {
  display: block;
  width: 100%;
}

.results-banner {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__heading {
    color: #ffffff;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    a {
      text-decoration: none;
      color: #fff;
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 700;
    }

    p {
      font-size: 0.8rem;
      font-weight: 600;
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 4rem;
      padding: 1.5rem 1rem;

      h1 {
        font-size: 1.625rem;
        margin-top: 0;
      }

      p {
        font-size: 1.375rem;
        margin-bottom: 0;
      }
    }
  }

  &__edit-button {
    display: none;
    @media screen and (min-width: 768px) {
      display: initial;
    }
    position: relative;
    a {
      width: 105px;
      line-height: 35px;
      background-color: #e8e8e8;
      box-shadow: 0px 3px 6px #00000029;
      display: inline-block;
      text-align: center;
      color: #000000;
      font-weight: 700;
      font-size: 0.875rem;
    }
  }
}

.slot-filters {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.pagelink {
  font-size: 0.9rem;
  position: relative;
}

.pagelink.active {
  font-weight: bold;

  &:after {
    position: absolute;
    bottom: 50%;
    height: 0;
    width: 0;
    left: 0%;
    transform: translate(-100%, 50%) rotate(90deg);
    border: 8px solid transparent;
    border-bottom-color: #eeeeee;
    content: "";
  }

  @media screen and (min-width: 768px) {
    &:after {
      position: absolute;
      bottom: 0%;
      height: 0;
      width: 0;
      left: 50%;
      transform: translate(-50%, calc(100% - calc(1rem - 2px)));
      border: 22px solid transparent;
      border-bottom-color: #eeeeee;
      content: "";
    }
  }
}

</style>
